body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: rgb(242,251,254);

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.brillant {
  backdrop-filter: brightness(180%);
  text-shadow: 2px 2px purple;
  color: rgb(14, 100, 108);
  margin: 1rem 5rem;
  font-weight: bold; 
  font-size:2rem;
  text-align: center; 
}

.articles-grid {
  display: grid;
  grid-gap: 3%;
  grid-template-columns: auto auto auto;
  justify-content: center;
  overflow: auto;
  margin: 1rem 0;
  padding: 0;
  height: auto;

}

@media (orientation: portrait) { 

  .articles-grid {
    top:3rem;
    grid-template-columns: repeat(2, 1fr);
    height: max-content;
  }
  @media (max-width: 600px) {
    .articles-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

